import {Outlet, useLocation} from 'react-router-dom'
import React from 'react'
import {PublicLayoutWrapper} from '@layouts/public-layout/styles.ts'
import ErrorBoundary from '@/components/error-boundary/ErrorBoundary'
import GlobalFallback from '@/components/error-boundary/GlobalFallback'
import Header from '@/components/header/Header'
import Footer from '@/components/footer/Footer'

const PublicLayout: React.FC = () => {
    const location = useLocation()
    return (
        <ErrorBoundary fallback={<GlobalFallback />}>
            <PublicLayoutWrapper>
                <Header />
                <div className="page-content">
                    <Outlet />
                </div>
                {location.pathname !== '/mint' && <Footer />}
            </PublicLayoutWrapper>
        </ErrorBoundary>
    )
}

export default PublicLayout
