import React, {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {ConnectorKey, connectors} from '@/features/web3/connectors/connectors'
import {Web3ReactHooks, Web3ReactProvider} from '@web3-react/core'
import {MetaMask} from '@web3-react/metamask'
import {CoinbaseWallet} from '@web3-react/coinbase-wallet'
import {Network} from '@web3-react/network'
import {envVars} from '@/envVars'

type ConnectorContextType = {
    selectedConnector: ConnectorKey
    setSelectedConnector: React.Dispatch<React.SetStateAction<ConnectorKey>>
}

const SelectedConnectorContext = createContext<ConnectorContextType | null>(null)

export const useSelectedConnectorContext = () => {
    const ctx = useContext(SelectedConnectorContext)
    if (!ctx) {
        throw new Error('Context must be used within provider')
    }
    return ctx
}

export const SelectedConnectorProvider: FC<PropsWithChildren> = ({children}) => {
    const [selectedConnector, setSelectedConnector] = useState<ConnectorKey>('network')

    const providerConnectors = useMemo(() => {
        return Object.values(connectors) as [MetaMask | CoinbaseWallet | Network, Web3ReactHooks][]
    }, [])

    const [connectorOvveride, {useAccount, useChainId, useIsActive}] = connectors[selectedConnector]

    const reset = useCallback(() => {
        connectorOvveride.deactivate?.()
        connectorOvveride.resetState()
        setSelectedConnector('network')
    }, [connectorOvveride])

    /**
     * Try to activate network connector on first mount
     */
    useEffect(() => {
        const [networkConnector] = connectors['network']
        networkConnector.activate().catch(() => {
            throw new Error('Cannot activate network connector!')
        })
    }, [])

    const account = useAccount()

    /**
     * Prevent app from breaking if user disconnect dapp from external app i.e. metamask extension
     */
    useEffect(() => {
        if (selectedConnector !== 'network' && !account) {
            reset()
        }
    }, [account, selectedConnector, reset])

    const chainId = useChainId()
    const isActive = useIsActive()

    /**
     * Prevent user from switching chain from extension, when using the app
     */
    useEffect(() => {
        if (!isActive) {
            //Still initializing
            return
        }
        if (chainId !== envVars.VITE_CHAIN_ID) {
            reset()
        }
    }, [chainId, isActive, reset])

    return (
        <SelectedConnectorContext.Provider value={{selectedConnector, setSelectedConnector}}>
            <Web3ReactProvider
                key={selectedConnector}
                connectors={providerConnectors}
                connectorOverride={connectorOvveride}
            >
                {children}
            </Web3ReactProvider>
        </SelectedConnectorContext.Provider>
    )
}
