import styled, {css} from 'styled-components'

export const WalletConnectorWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        width: 160px;
        border-radius: 6px;
        background-color: white;
        padding: ${spacing * 2}px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .wallet-icon {
            height: 100px;
            aspect-ratio: 1;
            object-fit: contain;
            margin-bottom: ${spacing * 2}px;
        }

        .wallet-connect {
            padding: ${spacing * 3}px;
        }

        &:hover {
            .wallet-icon {
                transform: scale(1.1);
                transition: all 0.5s ease-in-out;
            }
        }
        .wallet-label {
            white-space: nowrap;
            font-weight: 600;
        }
    `}
`
