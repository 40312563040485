import styled, {css} from 'styled-components'

export const GlobalFallBackWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .main-container {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 12}px;
            padding: ${spacing * 4}px;
        }

        h1 {
            font-size: 40px;
            line-height: 60px;
            font-weight: 700;
        }

        ${mediaQueries.m} {
            h1 {
                font-size: 24px;
                line-height: 36px;
            }
        }
    `}
`
