import {WalletConnectorKey} from '@/features/web3/connectors/connectors'
import {ReactNode, useState} from 'react'
import MetaMaskIcon from '@assets/icons/metamask.svg'
import CoinbaseIcon from '@assets/icons/coinbase.svg'
import {useSelectedConnectorContext} from '@/features/web3/context/ConnectorContext'
import {useWeb3React} from '@web3-react/core'
import {useWalletLogout} from '@/features/web3/wallet-auth'
import {XIcon} from '@/assets/icons/xIcon'
import WalletModal from './WalletModal'
import {ResponsiveButton} from '../button/Button'

const iconByConnectorKey: Record<WalletConnectorKey, ReactNode> = {
    metaMask: <img src={MetaMaskIcon} alt={'metamask-icon'} className={'wallet-icon'} />,
    coinbaseWallet: <img src={CoinbaseIcon} alt={'coinbase-icon'} className={'wallet-icon'} />
}

const ConnectWalletButton = () => {
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)
    const {selectedConnector} = useSelectedConnectorContext()
    const {account} = useWeb3React()
    const walletLogout = useWalletLogout()
    const walletConnected = selectedConnector !== 'network'

    const handleConnectButtonClick = () => {
        if (!walletConnected) {
            setIsWalletModalOpen(true)
            return
        }
    }

    let buttonContent
    if (!walletConnected) {
        buttonContent = 'CONNECT WALLET'
    } else {
        buttonContent = (
            <div className={'wallet-info-container'}>
                <span className={'account-info'}>
                    {iconByConnectorKey[selectedConnector]}
                    <div className={'account-address'}>
                        {account?.slice(0, 7)}...{account?.slice(-7)}
                    </div>
                    <div
                        className="close-icon-container"
                        onClick={e => {
                            e.stopPropagation()
                            walletLogout()
                        }}
                    >
                        <XIcon />
                    </div>
                </span>
            </div>
        )
    }
    return (
        <>
            <WalletModal open={isWalletModalOpen} onOpenChange={open => setIsWalletModalOpen(open)} />
            <ResponsiveButton onClick={handleConnectButtonClick} className={'header-button'}>
                {buttonContent}
            </ResponsiveButton>
        </>
    )
}

export default ConnectWalletButton
