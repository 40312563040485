import {createBrowserRouter} from 'react-router-dom'
import PublicLayout from '@layouts/public-layout/PublicLayout.tsx'
import {envVars} from './envVars'

const router = createBrowserRouter(
    [
        {
            element: <PublicLayout />,
            children: [
                {
                    path: '/',
                    lazy: () => import('./pages/mint')
                },
                {
                    path: '/thank-you',
                    lazy: () => import('./pages/thank-you')
                },
                {
                    path: '/*',
                    lazy: () => import('./pages/not-found')
                }
            ]
        }
    ],
    {
        basename: envVars.VITE_ROUTER_BASE_PATH || '/'
    }
)

export default router
