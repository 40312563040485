import {envVars} from '@/envVars'
import {initializeConnector} from '@web3-react/core'
import {Network} from '@web3-react/network'

export const [network, hooks] = initializeConnector<Network>(
    actions =>
        new Network({
            actions,
            urlMap: {
                11155111: `https://eth-sepolia.g.alchemy.com/v2/${envVars.VITE_ALCHEMY_KEY}`,
                1: `https://eth-mainnet.alchemyapi.io/v2/${envVars.VITE_ALCHEMY_KEY}`
            }
        })
)
