import {ParticleBgWrapper} from './styles'

const ParticleBg = () => {
    return (
        <ParticleBgWrapper>
            <div className="space">
                <div className="particle"></div>
                <div className="particle"></div>
                <div className="particle"></div>
                <div className="particle"></div>
            </div>
        </ParticleBgWrapper>
    )
}

export default ParticleBg
