import {hooks as networkHooks, network} from '@/features/web3/connectors/network'
import {hooks as metaMaskHooks, metaMask} from '@/features/web3/connectors/metamask'
import {coinbaseWallet, hooks as coinbaseWalletHooks} from '@/features/web3/connectors/coinbase'

export const connectors = {
    network: [network, networkHooks],
    metaMask: [metaMask, metaMaskHooks],
    coinbaseWallet: [coinbaseWallet, coinbaseWalletHooks]
} as const

export type ConnectorKey = keyof typeof connectors
export type WalletConnectorKey = Exclude<ConnectorKey, 'network'>
export const walletConnectorKeys = ['metaMask', 'coinbaseWallet'] satisfies WalletConnectorKey[]
