import {FC} from 'react'
import {GlobalFallBackWrapper} from './styles'
import PlanetDiv from '../planet-div/PlanetDiv'
import Button from '../button/Button'
import ParticleBg from '../particle-bg/ParticleBg'

const GlobalFallback: FC = () => {
    return (
        <GlobalFallBackWrapper>
            <ParticleBg />
            <PlanetDiv width={680} className="main-container">
                <h1>Something went wrong!</h1>
                <Button
                    onClick={() => {
                        window.location.assign('/')
                    }}
                >
                    Reload the page
                </Button>
            </PlanetDiv>
        </GlobalFallBackWrapper>
    )
}

export default GlobalFallback
