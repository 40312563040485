import {z} from 'zod'

export const envVars = z
    .object({
        VITE_ROUTER_BASE_PATH: z.string().optional(),
        VITE_CONTRACT_ADDRESS: z.string(),
        VITE_ALCHEMY_KEY: z.string(),
        VITE_CHAIN_ID: z.coerce.number(),
        VITE_COOKIE_PREFERENCES_LINK: z.string(),
        VITE_PRIVACY_POLICY_LINK: z.string(),
        VITE_LINKEDIN_LINK: z.string(),
        VITE_INSTAGRAM_LINK: z.string(),
        VITE_TWITTER_LINK: z.string(),
        VITE_WHATSAPP_LINK: z.string()
    })
    .parse(import.meta.env)
