import {WalletConnectorKey} from '@/features/web3/connectors/connectors'
import {FC, ReactNode} from 'react'
import {WalletConnectorWrapper} from '@components/wallet-connector/styles.ts'
import CoinbaseIcon from '@assets/icons/coinbase.svg'
import MetaMaskIcon from '@assets/icons/metamask.svg'
import {useWalletLogin} from '@/features/web3/wallet-auth'
import {toast} from 'react-hot-toast'
import {isMobile} from '@/utils'

type WalletConnectorProps = {
    connectorKey: WalletConnectorKey
    onConnectionSuccess: () => void
}

const dataByConnectorKey: Record<WalletConnectorKey, {label: string; icon: ReactNode}> = {
    metaMask: {
        label: 'MetaMask',
        icon: <img src={MetaMaskIcon} alt={'metamask-icon'} className={'wallet-icon'} />
    },
    coinbaseWallet: {
        label: 'Coinbase',
        icon: <img src={CoinbaseIcon} alt={'coinbase-icon'} className={'wallet-icon'} />
    }
}

const WalletConnector: FC<WalletConnectorProps> = ({connectorKey, onConnectionSuccess}) => {
    const {icon, label} = dataByConnectorKey[connectorKey]
    const walletLogin = useWalletLogin(toast.error)

    const handleClick = async () => {
        if (connectorKey === 'metaMask' && !window.ethereum) {
            if (isMobile()) {
                window.location.replace('https://metamask.app.link/dapp/')
            } else {
                toast.error('You need to install the metamask browser extension!')
            }
            return
        }
        await walletLogin(connectorKey)
        onConnectionSuccess()
    }

    return (
        <WalletConnectorWrapper role={'button'} onClick={handleClick}>
            {icon}
            <span className="wallet-label">{label}</span>
        </WalletConnectorWrapper>
    )
}

export default WalletConnector
