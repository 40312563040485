export const LinkedinIcon = ({size = 14}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.8018 16.4593H13.9376V11.9759C13.9376 10.9071 13.9182 9.53241 12.448 9.53241C10.9584 9.53241 10.7309 10.6978 10.7309 11.8997V16.4593H7.8688V7.24133H10.614V8.50116H10.6537C11.0368 7.77681 11.9704 7.01276 13.3645 7.01276C16.2663 7.01276 16.8018 8.92074 16.8018 11.4039V16.4593ZM4.63839 5.98257C3.71761 5.98257 2.97498 5.23783 2.97498 4.32033C2.97498 3.40389 3.71761 2.65916 4.63839 2.65916C5.5538 2.65916 6.29857 3.40389 6.29857 4.32033C6.29857 5.23783 5.5538 5.98257 4.63839 5.98257ZM3.20252 16.4593H6.07109V7.24133H3.20252V16.4593ZM18.2282 0H1.76589C0.98033 0 0.341797 0.623475 0.341797 1.39289V17.9219C0.341797 18.6914 0.98033 19.3159 1.76589 19.3159H18.2282C19.016 19.3159 19.6577 18.6914 19.6577 17.9219V1.39289C19.6577 0.623475 19.016 0 18.2282 0Z"
                fill="currentColor"
            />
        </svg>
    )
}
