import {HeaderWrapper} from '@components/header/styles.ts'
import ConnectWalletButton from './ConnectWalletButton'
import {useLocation, useNavigate} from 'react-router-dom'
import {ResponsiveButton} from '../button/Button'
import momLogo from '@/assets/images/MoM-Logo.png'

const Header = () => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <HeaderWrapper>
            <img src={momLogo} alt={'mom-logo'} className={'logo'} onClick={() => navigate('/')} />
            {location.pathname == '/' ? (
                <ConnectWalletButton />
            ) : (
                <ResponsiveButton onClick={() => navigate('/')}>GO TO MINT</ResponsiveButton>
            )}
        </HeaderWrapper>
    )
}

export default Header
