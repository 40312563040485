import styled, {css} from 'styled-components'

const variants = {
    primary: css`
        border: 1px solid white;

        &:disabled {
            border: 1px solid rgba(255, 255, 255, 0.3);
            color: rgba(255, 255, 255, 0.3);
            cursor: default;
        }
    `,
    secondary: css`
        border-bottom: 1px solid #fff;
        font-family: Roboto Mono, monospace;
        &:disabled {
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            color: rgba(255, 255, 255, 0.3);
            cursor: default;
        }
    `
} as const

const Button = styled.button<{variant?: 'primary' | 'secondary'}>`
    ${({theme: {spacing}, variant}) => css`
        padding: ${spacing * 4}px ${spacing * 7}px;
        text-align: center;
        color: white;
        border-radius: 46px;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        ${variants[variant ?? 'primary']}
    `}
`

export const ResponsiveButton = styled(Button)`
    ${({theme: {spacing, mediaQueries}}) => css`
        ${mediaQueries.m} {
            padding: ${spacing * 2}px ${spacing * 4}px;
        }
    `}
`

export default Button
