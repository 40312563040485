/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

class ErrorBoundary extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
        this.state = {hasError: false}
    }

    static getDerivedStateFromError() {
        return {hasError: true}
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback
        }

        return this.props.children
    }
}

export default ErrorBoundary
