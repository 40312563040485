export const TwitterIcon = ({size = 14}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_572_1110)">
                <path
                    d="M19.5086 2.49996C18.7106 3.06285 17.827 3.49338 16.892 3.77496C16.3901 3.19788 15.7231 2.78887 14.9812 2.60323C14.2392 2.41759 13.4582 2.46429 12.7437 2.737C12.0292 3.00972 11.4157 3.49529 10.9861 4.12805C10.5566 4.76082 10.3317 5.51024 10.342 6.27496V7.10829C8.87749 7.14626 7.42635 6.82147 6.1178 6.16283C4.80925 5.50419 3.68389 4.53215 2.84196 3.33329C2.84196 3.33329 -0.491374 10.8333 7.00863 14.1666C5.2924 15.3316 3.24792 15.9157 1.17529 15.8333C8.67529 20 17.842 15.8333 17.842 6.24996C17.8412 6.01783 17.8189 5.78629 17.7753 5.55829C18.6258 4.71953 19.226 3.66055 19.5086 2.49996Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_572_1110">
                    <rect width={size} height={size} fill="currentColor" transform="translate(0.341797)" />
                </clipPath>
            </defs>
        </svg>
    )
}
