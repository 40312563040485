import {useSelectedConnectorContext} from './context/ConnectorContext'
import {WalletConnectorKey, connectors} from './connectors/connectors'
import {envVars} from '@/envVars'
import {useCallback} from 'react'

const genericErrorMsg = 'Something went wrong! Please try again later'
const metaMaskPendingLoginError = 'There is a pending MetaMask action! Complete it then try again.'

export const useWalletLogin = (errorCb?: (msg: string) => void) => {
    const {setSelectedConnector} = useSelectedConnectorContext()

    return async (connectorKey: WalletConnectorKey) => {
        const [desiredConnector] = connectors[connectorKey]
        try {
            await desiredConnector.activate(envVars.VITE_CHAIN_ID)
            setSelectedConnector(connectorKey)
        } catch (error) {
            if (connectorKey === 'metaMask' && (error as {code?: number} | null)?.code === -32002) {
                errorCb?.(metaMaskPendingLoginError)
            } else {
                errorCb?.(genericErrorMsg)
            }
            console.error(error)
        }
    }
}

export const useWalletLogout = () => {
    const {setSelectedConnector} = useSelectedConnectorContext()
    return useCallback(() => setSelectedConnector('network'), [setSelectedConnector])
}
