import {FC} from 'react'
import {WalletConnectorsWrapper} from '@components/header/styles.ts'
import {walletConnectorKeys} from '@/features/web3/connectors/connectors'
import WalletConnector from '@components/wallet-connector/WalletConnector.tsx'
import Modal from '@components/modal/Modal.tsx'

type WalletModalProps = {
    open: boolean
    onOpenChange: (open: boolean) => void
}
const WalletModal: FC<WalletModalProps> = ({open, onOpenChange}) => {
    return (
        <Modal open={open} onOpenChange={onOpenChange}>
            <WalletConnectorsWrapper>
                {walletConnectorKeys.map(key => (
                    <WalletConnector connectorKey={key} key={key} onConnectionSuccess={() => onOpenChange(false)} />
                ))}
            </WalletConnectorsWrapper>
        </Modal>
    )
}

export default WalletModal
