import {spacing} from '@/theme'
import styled, {css} from 'styled-components'

export const FooterWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 4}px;
        padding: ${spacing * 6}px 0;
    `}

    a {
        cursor: pointer;
    }

    .social-media-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${spacing * 3}px;
    }

    .useful-links-container {
        display: flex;
        flex-wrap: wrap;
        gap: ${spacing * 4}px;

        font-size: 12px;
        line-height: 18px;
        font-weight: 400;

        a {
            text-decoration: underline;
        }

        * {
            padding-left: ${spacing * 4}px;
            padding-right: ${spacing * 4}px;
            margin-left: auto;
            margin-right: auto;
        }
    }
`
