import styled from 'styled-components'

export const ParticleBgWrapper = styled.div`
    @keyframes particles {
        0% {
            transform: translateY(0px);
        }
        100% {
            transform: translateY(-2560px);
        }
    }

    .space {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        background: black;
    }

    .particle,
    .particle::after {
        background: transparent;
    }

    .particle::after {
        position: absolute;
        content: '';
        top: 2560px;
    }

    .particle:nth-child(1) {
        animation: particles 60s linear infinite;
    }
    .particle:nth-child(1),
    .particle:nth-child(1)::after {
        box-shadow: 0 0 white, 1342px 1684px white, 2505px 2293px white, 1873px 347px white, 2254px 1148px white,
            664px 1217px white, 2124px 454px white, 64px 1088px white, 1361px 2294px white, 566px 1490px white,
            405px 140px white, 786px 52px white, 856px 917px white, 942px 2278px white, 2549px 129px white,
            913px 839px white, 808px 549px white, 171px 1717px white, 1934px 575px white, 601px 1577px white,
            516px 1343px white, 1353px 2303px white, 787px 1996px white, 1256px 794px white, 544px 1644px white,
            2147px 533px white, 1713px 2362px white, 2182px 1622px white, 1557px 689px white, 1036px 1736px white,
            354px 2485px white, 1480px 135px white, 2493px 2179px white, 461px 517px white, 343px 708px white,
            663px 1851px white, 189px 763px white, 416px 1249px white, 2319px 1880px white, 2024px 2270px white,
            330px 583px white, 617px 637px white, 310px 2064px white, 669px 1185px white, 1729px 2133px white,
            765px 162px white, 1579px 250px white, 142px 2455px white, 2224px 334px white, 1181px 1980px white,
            402px 1139px white, 2245px 2163px white, 2154px 2269px white, 1980px 1569px white, 184px 1888px white,
            2357px 1439px white, 1426px 575px white, 485px 1039px white, 987px 717px white, 2498px 1764px white,
            2286px 73px white, 71px 400px white, 2330px 837px white, 2412px 1438px white, 256px 1503px white,
            2517px 2463px white, 199px 2219px white, 951px 1428px white, 123px 1469px white, 1789px 285px white,
            1995px 2128px white, 1609px 1652px white, 2035px 67px white, 1223px 1508px white, 1526px 2182px white,
            2502px 1913px white, 2553px 253px white, 1850px 2503px white, 101px 2366px white, 1642px 2061px white,
            448px 1968px white, 1336px 99px white, 1077px 446px white, 258px 1233px white, 1474px 430px white,
            1667px 820px white, 1963px 661px white, 209px 2558px white, 722px 1656px white, 1260px 37px white,
            390px 472px white, 2077px 1573px white, 2169px 2544px white, 971px 392px white, 464px 2092px white,
            998px 226px white, 38px 2313px white, 898px 1267px white, 469px 576px white, 920px 1422px white,
            293px 2018px white, 654px 2437px white, 1726px 1144px white, 2379px 1086px white, 598px 910px white,
            854px 2246px white, 1368px 47px white, 1232px 1336px white, 760px 1079px white, 2216px 949px white,
            1232px 1020px white, 1828px 971px white, 2284px 1014px white, 768px 1252px white, 631px 101px white,
            1695px 1337px white, 342px 2137px white, 801px 714px white, 1717px 968px white, 1925px 722px white,
            1171px 2458px white, 1872px 528px white, 2526px 1021px white, 1116px 1354px white, 2358px 958px white,
            199px 1987px white, 1676px 1889px white, 1303px 193px white, 959px 1982px white, 1263px 1913px white,
            1695px 297px white, 495px 1020px white, 2207px 64px white, 1719px 2526px white, 2125px 2156px white,
            831px 371px white, 2481px 88px white, 2534px 1959px white, 149px 805px white, 181px 188px white,
            2251px 642px white, 2216px 1937px white, 1783px 2236px white, 1227px 1600px white, 291px 2112px white,
            2443px 573px white, 2307px 447px white, 2096px 1168px white, 2202px 560px white, 1566px 1862px white,
            1523px 733px white, 1262px 337px white, 1165px 2396px white, 827px 2455px white, 1241px 1475px white,
            631px 2240px white, 2129px 1418px white, 875px 1071px white, 1194px 1683px white, 1031px 1679px white,
            1540px 1103px white, 1217px 191px white, 56px 1430px white, 2295px 2162px white, 806px 784px white,
            99px 44px white, 2036px 1917px white, 851px 292px white, 311px 2552px white, 2144px 2537px white,
            1558px 2325px white, 2170px 1924px white, 2400px 166px white, 1656px 2224px white, 1241px 1832px white,
            328px 496px white, 1636px 2135px white, 1075px 1831px white, 2344px 550px white, 1872px 1176px white,
            1245px 1508px white, 890px 1264px white, 1821px 1271px white, 2248px 534px white, 1430px 563px white,
            235px 1195px white, 1938px 1533px white, 1936px 829px white, 412px 610px white, 1137px 505px white,
            1771px 733px white, 702px 974px white, 2269px 1901px white, 2184px 506px white, 811px 2548px white,
            104px 2066px white, 536px 2539px white, 2429px 1475px white, 70px 1224px white, 1238px 558px white,
            1792px 1288px white, 1422px 2352px white, 1677px 1240px white, 2328px 112px white, 238px 984px white,
            1023px 1792px white, 1727px 1777px white, 2349px 1251px white, 2111px 413px white, 1086px 1184px white,
            1207px 54px white, 1766px 168px white, 644px 2328px white, 199px 2332px white, 1254px 2355px white,
            274px 1789px white, 1518px 2496px white, 2491px 932px white, 436px 109px white, 1830px 1500px white,
            1055px 1008px white, 378px 623px white, 2080px 2044px white, 260px 753px white, 2097px 1349px white,
            2237px 197px white, 1834px 177px white, 2406px 791px white, 1723px 212px white, 916px 1407px white,
            369px 2071px white, 734px 1914px white, 427px 1570px white, 196px 949px white, 192px 2404px white,
            1101px 314px white, 1577px 2019px white, 774px 1939px white, 1656px 349px white, 109px 610px white,
            528px 1799px white, 852px 726px white, 2416px 1988px white, 1034px 1162px white, 2119px 1063px white,
            42px 715px white, 2194px 2495px white, 333px 774px white, 1176px 1382px white, 983px 2240px white,
            31px 2498px white, 1516px 1556px white, 1908px 1475px white, 595px 1820px white, 210px 656px white,
            547px 2451px white, 2228px 1029px white, 2513px 2250px white, 1583px 1582px white, 1844px 545px white,
            1871px 418px white, 968px 2254px white, 466px 1154px white, 727px 2240px white, 720px 2005px white,
            1968px 1398px white, 1111px 2009px white, 654px 2148px white, 1003px 1361px white, 1971px 1393px white,
            2531px 2443px white, 912px 1604px white, 323px 1664px white, 2447px 933px white, 560px 2422px white,
            2004px 194px white, 669px 995px white, 1199px 1685px white, 602px 43px white, 988px 1270px white,
            535px 1029px white, 2371px 1127px white, 372px 1223px white, 2549px 2151px white, 484px 2279px white,
            1346px 916px white, 375px 1444px white, 1262px 1391px white, 2158px 2478px white, 1254px 792px white,
            867px 520px white, 2144px 2376px white, 1477px 1021px white, 2539px 1958px white, 2017px 702px white,
            201px 1943px white, 1573px 776px white, 14px 2265px white, 2139px 1291px white, 621px 1641px white,
            299px 2154px white, 433px 2250px white, 1037px 243px white, 2094px 801px white, 118px 990px white,
            1843px 878px white, 2500px 1279px white, 2097px 2246px white, 2045px 70px white, 1274px 1480px white,
            962px 674px white, 2497px 160px white, 2516px 278px white, 841px 2050px white, 1120px 944px white,
            1638px 678px white, 2511px 1637px white, 1727px 1365px white, 2100px 1619px white, 506px 52px white,
            442px 817px white, 1753px 2557px white, 1751px 2237px white, 2084px 707px white, 739px 2293px white,
            2528px 294px white, 1805px 316px white, 477px 2105px white, 369px 2487px white, 1876px 826px white,
            470px 1586px white, 158px 1472px white, 380px 2479px white, 1024px 1628px white, 585px 1707px white,
            840px 1241px white, 2052px 963px white, 2390px 94px white, 660px 1875px white, 1020px 633px white,
            409px 355px white, 1089px 1012px white, 583px 899px white, 388px 1072px white, 69px 1752px white,
            1852px 1136px white, 721px 722px white, 1551px 2204px white, 111px 2475px white, 1070px 1954px white,
            96px 2096px white, 466px 1253px white, 664px 360px white, 143px 1121px white, 1793px 1301px white,
            647px 187px white, 2049px 2237px white, 596px 819px white, 1412px 2200px white, 272px 185px white,
            1087px 2200px white, 1185px 342px white, 2317px 330px white, 19px 86px white, 909px 2226px white,
            2083px 760px white, 2428px 1626px white, 2507px 913px white, 1718px 864px white, 1175px 402px white,
            559px 131px white, 1839px 1285px white, 2346px 1739px white, 1798px 1369px white, 2268px 1537px white,
            458px 614px white, 2383px 1267px white, 927px 2163px white, 1604px 2036px white, 260px 2543px white,
            1953px 804px white, 1010px 940px white, 1126px 648px white, 2174px 1776px white, 1901px 1051px white,
            1988px 298px white, 863px 1934px white, 799px 2337px white, 790px 1913px white, 2346px 728px white,
            1392px 5px white, 1682px 401px white, 613px 1686px white, 1019px 1687px white, 81px 2125px white,
            2475px 2015px white, 311px 2026px white, 1593px 2480px white, 716px 425px white, 1416px 1336px white,
            2080px 2159px white, 1705px 1623px white, 183px 838px white, 1392px 1479px white, 2064px 2422px white,
            2099px 231px white, 1308px 2185px white, 590px 570px white, 721px 2445px white, 666px 310px white,
            1609px 2425px white, 1441px 1210px white, 1775px 2272px white, 17px 1774px white, 2511px 1116px white,
            590px 2143px white, 1324px 1163px white, 81px 1626px white, 167px 183px white, 460px 154px white,
            1929px 430px white, 1163px 1506px white, 728px 2305px white, 1326px 2446px white, 326px 2265px white,
            2448px 2471px white, 1062px 1677px white, 807px 2062px white, 1607px 1126px white, 728px 790px white,
            488px 2245px white, 1706px 641px white, 1702px 1882px white, 1089px 2437px white, 2150px 254px white,
            557px 1550px white, 1883px 1975px white, 1427px 1389px white, 1707px 1879px white, 1373px 747px white,
            1269px 1217px white, 217px 1863px white, 2268px 392px white, 942px 1534px white, 2038px 2289px white,
            2032px 602px white, 1744px 760px white, 2261px 684px white, 1259px 1758px white, 1727px 2066px white,
            599px 891px white, 611px 1947px white, 1494px 2054px white, 675px 10px white, 1593px 1689px white,
            1327px 640px white, 2068px 1621px white, 705px 614px white, 1846px 1130px white, 125px 1257px white,
            2502px 2472px white, 200px 1563px white, 1524px 641px white, 2351px 778px white, 1454px 2063px white,
            990px 2274px white, 1912px 744px white, 2121px 726px white, 2100px 1575px white, 417px 1580px white,
            1116px 614px white, 1788px 1726px white, 1296px 2088px white, 1523px 2091px white, 1252px 2447px white,
            1864px 1559px white, 1471px 1072px white, 1778px 822px white, 1916px 534px white, 2010px 722px white,
            1840px 869px white, 644px 1617px white, 2249px 2039px white, 2229px 1928px white, 1888px 420px white,
            685px 933px white, 1107px 701px white, 1876px 1835px white, 721px 2532px white, 1935px 2244px white,
            983px 1340px white, 39px 706px white, 2337px 97px white, 2552px 245px white, 1272px 2428px white,
            179px 1828px white, 1913px 1568px white, 210px 47px white, 2293px 863px white, 254px 724px white,
            1470px 1359px white, 332px 1491px white, 2311px 301px white, 1480px 2560px white, 251px 96px white,
            1619px 2176px white, 2469px 1038px white, 1793px 2288px white, 284px 1904px white, 850px 841px white,
            1688px 1752px white, 192px 919px white, 472px 480px white, 121px 107px white, 1653px 1482px white,
            109px 2009px white, 74px 731px white, 315px 947px white, 64px 834px white, 957px 2170px white,
            326px 2239px white, 95px 1971px white, 1174px 1616px white, 780px 152px white, 1588px 404px white,
            68px 2540px white, 749px 78px white, 236px 2351px white, 477px 1650px white, 440px 822px white,
            1205px 2141px white, 586px 403px white, 952px 90px white, 795px 1203px white, 2084px 999px white,
            637px 1108px white, 1793px 33px white, 2340px 1486px white, 211px 2310px white, 2483px 2089px white,
            2267px 1470px white, 438px 2247px white, 209px 1777px white, 2397px 2294px white, 2164px 100px white,
            2px 2558px white, 79px 1504px white, 181px 1658px white, 1608px 2105px white, 765px 650px white,
            2382px 2354px white, 1688px 2285px white, 1479px 155px white, 636px 2552px white, 618px 1633px white,
            1930px 108px white, 566px 2165px white, 1961px 1007px white, 2497px 2245px white, 735px 1855px white,
            2235px 2203px white, 1189px 1026px white, 467px 2363px white, 2079px 2464px white, 1699px 2156px white,
            452px 318px white, 978px 1095px white, 2001px 148px white, 2474px 1317px white, 1983px 2216px white,
            2060px 1258px white, 1359px 265px white, 2088px 1280px white, 2314px 1661px white, 1579px 21px white,
            171px 1309px white, 294px 2236px white, 925px 1064px white, 2269px 1629px white, 11px 358px white,
            808px 385px white, 1816px 128px white, 477px 309px white, 1993px 2126px white, 1256px 2095px white,
            1395px 1265px white, 944px 2399px white, 2345px 2496px white, 1359px 1856px white, 1628px 1581px white,
            1477px 777px white, 1562px 1422px white, 264px 693px white, 2244px 21px white, 1372px 854px white,
            1615px 1537px white, 1504px 2371px white, 1465px 1518px white, 1315px 1782px white, 590px 996px white,
            1911px 391px white;
        width: 1px;
        height: 1px;
    }
    .particle:nth-child(2) {
        animation: particles 120s linear infinite;
    }
    .particle:nth-child(2),
    .particle:nth-child(2)::after {
        box-shadow: 0 0 white, 1408px 312px white, 297px 1760px white, 601px 1087px white, 634px 240px white,
            1746px 678px white, 1697px 37px white, 214px 902px white, 877px 39px white, 1569px 4px white,
            401px 184px white, 1025px 1134px white, 1080px 1306px white, 499px 337px white, 1508px 2232px white,
            2204px 1556px white, 586px 1879px white, 344px 863px white, 1204px 471px white, 673px 1248px white,
            573px 1832px white, 1845px 259px white, 559px 1055px white, 1680px 902px white, 2311px 1833px white,
            736px 1025px white, 1390px 1116px white, 2450px 1794px white, 1897px 2148px white, 770px 1959px white,
            2023px 2365px white, 991px 1423px white, 1590px 585px white, 1854px 1021px white, 691px 2464px white,
            552px 471px white, 418px 2471px white, 1623px 40px white, 662px 199px white, 1837px 635px white,
            2016px 1020px white, 2142px 2041px white, 44px 868px white, 271px 656px white, 2248px 1267px white,
            2027px 31px white, 204px 1675px white, 620px 147px white, 1894px 821px white, 2105px 1353px white,
            2237px 1282px white, 375px 766px white, 379px 2135px white, 2321px 78px white, 91px 2160px white,
            8px 909px white, 2547px 1557px white, 542px 1439px white, 2264px 50px white, 555px 521px white,
            2071px 815px white, 2296px 221px white, 73px 2523px white, 2391px 213px white, 2290px 405px white,
            1594px 1130px white, 141px 2397px white, 1402px 829px white, 2264px 238px white, 1401px 2455px white,
            1390px 1994px white, 954px 620px white, 581px 1433px white, 857px 1481px white, 672px 2066px white,
            423px 1928px white, 2244px 1606px white, 2297px 2053px white, 1195px 433px white, 1260px 257px white,
            260px 56px white, 498px 548px white, 2142px 334px white, 2454px 7px white, 1312px 327px white,
            1663px 214px white, 1239px 1430px white, 1118px 589px white, 682px 1513px white, 2264px 1314px white,
            1342px 685px white, 1896px 1059px white, 79px 1580px white, 2027px 371px white, 1864px 2199px white,
            854px 1791px white, 312px 1262px white, 1521px 1532px white, 308px 2540px white, 2433px 1124px white,
            2291px 25px white, 740px 63px white, 2022px 2349px white, 2164px 393px white, 1911px 1363px white,
            719px 434px white, 493px 288px white, 2442px 9px white, 134px 1429px white, 828px 2081px white,
            1133px 1356px white, 249px 2361px white, 1839px 1029px white, 570px 1160px white, 325px 429px white,
            2522px 225px white, 373px 1763px white, 541px 856px white, 189px 2319px white, 1035px 823px white,
            65px 1417px white, 2520px 2410px white, 111px 323px white, 460px 1308px white, 2153px 495px white,
            733px 87px white, 1523px 2059px white, 2465px 393px white, 2480px 2181px white, 2244px 1716px white,
            454px 664px white, 1914px 1365px white, 1648px 1518px white, 966px 1235px white, 2053px 677px white,
            1082px 1209px white, 1995px 1355px white, 1222px 781px white, 2463px 54px white, 607px 580px white,
            1109px 1434px white, 1363px 1496px white, 1709px 423px white, 1655px 193px white, 692px 2461px white,
            930px 28px white, 659px 1425px white, 1689px 299px white, 1134px 1220px white, 776px 2488px white,
            1332px 927px white, 1809px 44px white, 208px 1681px white, 1812px 909px white, 202px 356px white,
            294px 1502px white, 2155px 2437px white, 2137px 228px white, 92px 144px white, 253px 844px white,
            2488px 82px white, 1704px 2512px white, 1047px 1459px white, 425px 1071px white, 2170px 2036px white,
            13px 374px white, 288px 2127px white, 1914px 1622px white, 1980px 967px white, 1960px 2268px white,
            2269px 1563px white, 2134px 767px white, 1817px 2369px white, 1458px 229px white, 1976px 654px white,
            956px 537px white, 1342px 1321px white, 2251px 1093px white, 218px 2282px white, 1340px 1587px white,
            173px 1701px white, 1449px 1377px white, 1350px 416px white, 1527px 1167px white, 1135px 784px white,
            2006px 1526px white, 864px 2195px white, 2464px 2346px white, 913px 896px white, 1642px 626px white,
            782px 367px white, 593px 2423px white, 1005px 1391px white, 2162px 1849px white, 1299px 1735px white,
            2393px 74px white, 2472px 439px white, 946px 2430px white, 1432px 1628px white, 2010px 293px white,
            1265px 136px white;
        width: 2px;
        height: 2px;
    }
    .particle:nth-child(3) {
        animation: particles 180s linear infinite;
    }
    .particle:nth-child(3),
    .particle:nth-child(3)::after {
        box-shadow: 0 0 white, 1950px 1915px white, 1695px 1684px white, 2549px 2211px white, 1567px 2334px white,
            281px 1415px white, 432px 691px white, 1194px 676px white, 702px 1964px white, 44px 74px white,
            1838px 289px white, 601px 1949px white, 1143px 72px white, 433px 529px white, 2480px 1098px white,
            1190px 2387px white, 461px 515px white, 1282px 2055px white, 2107px 1097px white, 2271px 197px white,
            525px 911px white, 1221px 1892px white, 1524px 1301px white, 1756px 1372px white, 1329px 1691px white,
            2403px 346px white, 2078px 2155px white, 1034px 877px white, 336px 860px white, 287px 1930px white,
            665px 346px white, 1406px 1827px white, 722px 601px white, 511px 1331px white, 645px 1626px white,
            546px 1571px white, 13px 1430px white, 1963px 902px white, 1390px 2025px white, 939px 822px white,
            2032px 1384px white, 2232px 2083px white, 1316px 1716px white, 2008px 803px white, 1994px 1929px white,
            1651px 1624px white, 382px 1911px white, 162px 2560px white, 2010px 933px white, 1231px 2043px white,
            1214px 346px white, 318px 2198px white, 1310px 312px white, 1232px 255px white, 1291px 1857px white,
            1536px 1849px white, 743px 360px white, 994px 1004px white, 2334px 2389px white, 229px 273px white,
            469px 2081px white, 287px 2417px white, 655px 1945px white, 1477px 809px white, 1520px 11px white,
            1946px 672px white, 1556px 1986px white, 758px 1600px white, 377px 2366px white, 1896px 1352px white,
            696px 2092px white, 2141px 2186px white, 2524px 365px white, 854px 1519px white, 1250px 2363px white,
            1621px 2506px white, 1510px 228px white, 2383px 1892px white, 674px 1680px white, 1611px 1101px white,
            685px 2173px white, 1510px 1272px white, 1896px 1004px white, 592px 672px white, 671px 52px white,
            886px 1304px white, 1718px 95px white, 1806px 1008px white, 1307px 2310px white, 788px 362px white,
            2111px 2122px white, 1383px 1051px white, 791px 668px white, 1738px 990px white, 1265px 700px white,
            398px 627px white, 1893px 27px white, 2294px 1015px white, 1884px 2362px white, 2394px 853px white,
            471px 1695px white;
        width: 3px;
        height: 3px;
    }
    .particle:nth-child(4) {
        animation: particles 400s linear infinite;
    }
    .particle:nth-child(4),
    .particle:nth-child(4)::after {
        box-shadow: 0 0 white, 1950px 1915px white, 1695px 1684px white, 2549px 2211px white, 1567px 2334px white,
            281px 1415px white, 432px 691px white, 1194px 676px white, 702px 1964px white, 44px 74px white,
            1838px 289px white, 601px 1949px white, 1143px 72px white, 433px 529px white, 2480px 1098px white,
            1190px 2387px white, 461px 515px white, 1282px 2055px white, 2107px 1097px white, 2271px 197px white,
            525px 911px white, 1221px 1892px white, 1524px 1301px white, 1756px 1372px white, 1329px 1691px white,
            2403px 346px white, 2078px 2155px white, 1034px 877px white, 336px 860px white, 287px 1930px white,
            665px 346px white, 1406px 1827px white, 722px 601px white, 511px 1331px white, 645px 1626px white,
            546px 1571px white, 13px 1430px white, 1963px 902px white, 1390px 2025px white, 939px 822px white,
            2032px 1384px white, 2232px 2083px white, 1316px 1716px white, 2008px 803px white, 1994px 1929px white,
            1651px 1624px white, 382px 1911px white, 162px 2560px white, 2010px 933px white, 1231px 2043px white,
            1214px 346px white, 318px 2198px white, 1310px 312px white, 1232px 255px white, 1291px 1857px white,
            1536px 1849px white, 743px 360px white, 994px 1004px white, 2334px 2389px white, 229px 273px white,
            469px 2081px white, 287px 2417px white, 655px 1945px white, 1477px 809px white, 1520px 11px white,
            1946px 672px white, 1556px 1986px white, 758px 1600px white, 377px 2366px white, 1896px 1352px white,
            696px 2092px white, 2141px 2186px white, 2524px 365px white, 854px 1519px white, 1250px 2363px white,
            1621px 2506px white, 1510px 228px white, 2383px 1892px white, 674px 1680px white, 1611px 1101px white,
            685px 2173px white, 1510px 1272px white, 1896px 1004px white, 592px 672px white, 671px 52px white,
            886px 1304px white, 1718px 95px white, 1806px 1008px white, 1307px 2310px white, 788px 362px white,
            2111px 2122px white, 1383px 1051px white, 791px 668px white, 1738px 990px white, 1265px 700px white,
            398px 627px white, 1893px 27px white, 2294px 1015px white, 1884px 2362px white, 2394px 853px white,
            471px 1695px white;
    }
`
