import styled, {css} from 'styled-components'

export const PublicLayoutWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        height: 100%;
        width: 100%;
        position: relative;
        background-color: black;

        padding: ${spacing * 15}px ${spacing * 15}px 0 ${spacing * 15}px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        overflow-x: hidden;

        .page-content {
            flex-grow: 1;
        }

        ${mediaQueries.m} {
            padding: ${spacing * 10}px ${spacing * 10}px 0 ${spacing * 10}px;
        }

        ${mediaQueries.s} {
            padding: ${spacing * 5}px ${spacing * 5}px 0 ${spacing * 5}px;
        }
    `}
`
