import {RouterProvider} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'
import {getAppTheme, GlobalStyles} from '@/theme'
import router from './router.tsx'
import {Toaster} from 'react-hot-toast'
import {SelectedConnectorProvider} from '@/features/web3/context/ConnectorContext.tsx'
import ErrorBoundary from './components/error-boundary/ErrorBoundary.tsx'
import GlobalFallback from './components/error-boundary/GlobalFallback.tsx'
import {envVars} from './envVars.ts'
import * as Tooltip from '@radix-ui/react-tooltip'

function App() {
    if (!window.location.pathname.startsWith(envVars.VITE_ROUTER_BASE_PATH || '/')) {
        window.location.assign(envVars.VITE_ROUTER_BASE_PATH || '/')
    }

    return (
        <ThemeProvider theme={getAppTheme()}>
            <Tooltip.Provider>
                <GlobalStyles />
                <ErrorBoundary fallback={<GlobalFallback />}>
                    <SelectedConnectorProvider>
                        <Toaster />
                        <RouterProvider router={router} />
                    </SelectedConnectorProvider>
                </ErrorBoundary>
            </Tooltip.Provider>
        </ThemeProvider>
    )
}

export default App
