import styled, {css} from 'styled-components'

const PlanetDiv = styled.div<{width: number}>`
    ${({width}) => css`
        width: 100%;
        max-width: ${width}px;
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1;
        border-radius: 100%;
        box-shadow: -2px -3px 12px -3px #ffffff, 0px 4px 4px 0px #00000040;
    `}
`

export default PlanetDiv
