import {TwitterIcon} from '@/assets/icons/twitterIcon'
import {FooterWrapper} from './styles'
import {LinkedinIcon} from '@/assets/icons/linkedinIcon'
import {InstagramIcon} from '@/assets/icons/instagramIcon'
import {envVars} from '@/envVars'

const Footer = () => {
    return (
        <FooterWrapper>
            <div className="social-media-container">
                <a href={envVars.VITE_TWITTER_LINK} target="_black" rel="noopener noreferrer">
                    <TwitterIcon size={20} />
                </a>
                <a
                    style={{display: 'none'}}
                    href={envVars.VITE_LINKEDIN_LINK}
                    target="_black"
                    rel="noopener noreferrer"
                >
                    <LinkedinIcon size={20} />
                </a>
                <a href={envVars.VITE_INSTAGRAM_LINK} target="_black" rel="noopener noreferrer">
                    <InstagramIcon size={20} />
                </a>
            </div>
            <div className="useful-links-container">
                <a
                    href={envVars.VITE_COOKIE_PREFERENCES_LINK}
                    title="Cookie Policy"
                    target="_black"
                    rel="noopener noreferrer"
                >
                    Cookie Policy
                </a>
                <a
                    href={envVars.VITE_PRIVACY_POLICY_LINK}
                    title="Privacy Policy"
                    target="_black"
                    rel="noopener noreferrer"
                >
                    Privacy policy
                </a>

                <a
                    onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        window?._iub?.cs?.api.ui?.openPreferences?.()
                    }}
                >
                    Privacy Preference
                </a>

                <a
                    onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        window?._iub?.cs?.api?.ui.showPPUsprSection?.()
                    }}
                >
                    Notice at Collection
                </a>

                <span>Copyright MoM2023</span>
            </div>
        </FooterWrapper>
    )
}

export default Footer
