import {palette} from './palette.ts'
import mediaQueries from './mediaQueries.ts'
import ResetStyles from './reset.ts'
import {shadows} from './shadows.ts'
import {css} from 'styled-components'
import {typography} from './typography.ts'

const GlobalStyles = ResetStyles
export const spacing = 4

const getAppTheme = () => {
    const zIndex = {
        modalOverlay: 10,
        modal: 11
    }

    const truncateText = css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `

    return {
        spacing,
        zIndex,
        mediaQueries,
        palette,
        typography,
        shadows,
        truncateText
    }
}

export {GlobalStyles, getAppTheme}
