import styled, {css} from 'styled-components'
import {spacing} from '@/theme'

export const HeaderWrapper = styled.div`
    ${({theme: {mediaQueries}}) => css`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-button {
            width: 240px;
        }

        .logo {
            height: 48px;
            cursor: pointer;
        }

        .wallet-info-container {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .wallet-icon {
                width: 14px;
                aspect-ratio: 1;
            }

            .account-info {
                display: flex;
                align-items: center;
                gap: ${spacing * 2}px;
            }

            .account-address {
                flex-grow: 1;
                overflow: hidden;
                white-space: nowrap;
            }

            .close-icon-container {
                display: grid;
                cursor: pointer;
            }
        }

        ${mediaQueries.m} {
            .logo {
                zoom: 0.7;
            }

            .header-button {
                width: 200px;
            }
        }
    `}
`

export const WalletConnectorsWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 8}px;

        ${mediaQueries.m} {
            flex-direction: column;
        }
    `}
`
