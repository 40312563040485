import * as Dialog from '@radix-ui/react-dialog'
import styled, {css} from 'styled-components'

export const StyledDialogOverlay = styled(Dialog.Overlay)`
    backdrop-filter: blur(12px);
    position: fixed;
    inset: 0;
`

export const StyledDialogContent = styled(Dialog.Content)`
    ${() => css`
        color: black;
        border-radius: 6px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `}
`
