import * as Dialog from '@radix-ui/react-dialog'
import {DialogProps} from '@radix-ui/react-dialog'
import {FC, PropsWithChildren} from 'react'
import {StyledDialogContent, StyledDialogOverlay} from '@components/modal/styles.ts'

const Modal: FC<DialogProps & PropsWithChildren> = ({children, ...props}) => (
    <Dialog.Root {...props}>
        <Dialog.Portal>
            <StyledDialogOverlay />
            <StyledDialogContent>{children}</StyledDialogContent>
        </Dialog.Portal>
    </Dialog.Root>
)

export default Modal
